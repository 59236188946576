<template>
    <div class="page1">
        <EForm :formColumns="formColumns"
               :rowSize="4"
               :optionsBtn="true"
               :actionBtn="false"
               :formData="searchForm"
               ref="form"
               @onSearch="onSearch"
               :searchFlag="true"
               :exportShow="false"
               @getChange="changeSelect">
        </EForm>
        <div class="mt20 fs15 flex flex-space-between">
            <span>
                符合条件记录：共{{total.feeNum}}单，{{total.totalAmount}}元  查表电费合计：{{total.electricPriceTotal}}元；    查表电费服务费：{{total.electricServicePriceTotal}}元
            </span>
            <div>
                <el-button type="primary" size="small" @click="dialogShow">录入费用</el-button>
                <el-button type="primary" size="small" @click="dialogVisible2=true">批量导入</el-button>
            </div>

        </div>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size">
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="editDialog(scope.row)"
                                 :disabled="scope.row.status==1"
                        >
                            修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row.orderId)"
                                 :disabled="scope.row.status==1"
                        >
                            删除
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
<!--        费用录入-->
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
                 width="800px"
                 @handleClick="saveData"
                 :disabled="saveDisabled">
            <el-form inline size="small">
                <el-form-item label="摊位号">
                    <el-select v-model="boothId" filterable placeholder="请选择" @change="getContractByBoothId">
                        <el-option
                                v-for="item in options1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form inline label-width="100px" label-position="left" :model="form" v-if="boothId">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="区域">

                            <div>{{ form.areaName || '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="位置">

                            <div>{{ form.placeName|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="摊位">

                            <div>{{ form.boothCode|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="身份证号码">

                            <div>{{ form.idCard|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="合同开始日期">
                            <div>{{ form.inDate|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="负责人名称">
                            <div>{{ form.signPerson|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系电话">
                            <div>{{ form.phone|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="摊位面积">
                            <div>{{ form.areas|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="合同类别">
                            <div>{{ form.contractTypeCn|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="合同到期日期">
                            <div>{{ form.expireDate|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
            <el-form inline label-width="130px" label-position="left" :rules="formRules" :model="form" ref="form">
                <el-row>
                    <el-col :span="12" :sm="12">
                        <el-form-item label="查表电费" prop="electricMeterPrice">
                            <el-input class="number" type="number" v-model.number="form.electricMeterPrice"
                                      style="width: 180px;"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" :sm="12">
                        <el-form-item label="查表服务管理费" prop="electricMeterServicePrice">
                            <el-input class="number" type="number" v-model="form.electricMeterServicePrice"
                                      style="width: 180px;"/>
                        </el-form-item>
                    </el-col>
                  <el-col :span="12" :sm="12">
                    <el-form-item label="上月抄表底数" prop="lastMonthDegrees">
                      <el-input @input="reloadPrice1" class="number" type="number" v-model="form.lastMonthDegrees"
                                style="width: 180px;"/>
                    </el-form-item>
                  </el-col>
                    <el-col :span="12" :sm="12">
                      <el-form-item label="本月抄表底数" prop="lastDegrees">
                        <el-input @input="reloadPrice" class="number" type="number" v-model="form.lastDegrees"
                                  style="width: 180px;"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" :sm="12">
                        <el-form-item label="是否需要财务确认">
                            <el-radio-group v-model="form.isConfirm">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" :sm="12">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="form.remark" style="width: 180px;"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </EDialog>
<!--        批量导入-->
        <EDialog :dialogVisible="dialogVisible2" :title="title" @handleClose="cancelDialog"
                 width="800px"
                 @handleClick="mutiSaveData"
                 :disabled="saveDisabled">
            <el-form>
                <el-form-item label="选择文件">
                    <EUpload :max="1" @getList="getList" :fileTypes="['xlsx','xls']" :initList="multiForm.file?[multiForm.file]:[]">
                        <a :href="`${baseUrl}template/查表电费导入模板.xlsx`" download=""
                           class="download">下载模板</a>
                    </EUpload>

                </el-form-item>
            </el-form>
            <el-form>
                <el-form-item label="是否需要财务确认">
                    <el-radio-group v-model="multiForm.isConfirm">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </EDialog>
<!--        修改费用录入-->
        <EDialog :dialogVisible="editDialogVisible" :title="title" @handleClose="cancelDialog"
                 width="800px"
                 @handleClick="mutiSave"
                 :disabled="saveDisabled">
            <div class="mb20">
                <span class="pr20">摊位号<span class="pl10">{{form.boothCode}}</span></span>
            </div>
            <el-form inline label-width="100px" label-position="left" :model="form" ref="form">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="区域">
                            <div>{{ form.areaName || '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="位置">

                            <div>{{ form.placeName|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="摊位">

                            <div>{{ form.boothCode|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="身份证号码">

                            <div>{{ form.idCard|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="合同开始日期">
                            <div>{{ form.inDate|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="负责人名称">
                            <div>{{ form.signPerson|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系电话">
                            <div>{{ form.phone|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="摊位面积">
                            <div>{{ form.areaName|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="合同类别">
                            <div>{{ form.contractTypeCn|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="合同到期日期">
                            <div>{{ form.expireDate|| '-'}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div>
                <div class="title">
                    {{form.takeReturnProjectName}}
                </div>

                <el-form inline label-width="140px" label-position="left" :rules="formRules" :model="form" ref="form">
                    <el-row>
                        <el-col :span="12" :sm="12">
                            <el-form-item label="查表电费" prop="electricMeterPrice">
                                <el-input readonly class="number" type="number" v-model.number="form.electricMeterPrice"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :sm="12">
                            <el-form-item label="查表服务管理费" prop="electricMeterServicePrice">
                                <el-input readonly class="number" type="number" v-model="form.electricMeterServicePrice"/>
                            </el-form-item>
                        </el-col>
                      <el-col :span="12" :sm="12">
                        <el-form-item label="上月抄表底数" prop="lastMonthDegrees">
                          <el-input @input="reloadPrice1" class="number" type="number" v-model="form.lastMonthDegrees"
                                    style="width: 180px;"/>
                        </el-form-item>
                      </el-col>
                        <el-col :span="12" :sm="12">
                          <el-form-item label="本月抄表底数" prop="lastDegrees">
                            <el-input @input="reloadPrice" class="number" type="number" v-model="form.lastDegrees"
                                      style="width: 180px;"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12" :sm="12">
                            <el-form-item label="是否需要财务确认">
                                <el-radio-group v-model="form.isConfirm">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="2">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :sm="12">
                            <el-form-item label="备注">
                                <el-input type="textarea" v-model="form.remark"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  import EUpload from "@/components/EUpload";
  import * as math from "mathjs";

  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '交费单号',
            prop: 'orderNum'
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '查表电费',
            prop: 'electricMeterPrice'
          },
          {
            label: '查表电费服务费',
            prop: 'electricMeterServicePrice'
          },
          {
            label: '上月抄表底数',
            prop: 'lastMonthDegrees'
          },
          {
            label: '本月抄表底数',
            prop: 'lastDegrees'
          },
          {
            label: '录入人',
            prop: 'createUserCn'
          },
          {
            label: '录入时间',
            prop: 'createTime'
          },
          {
            label: '财务确认人',
            prop: 'confirmPersonName'
          },
          {
            label: '财务确认',
            prop: 'confirmStatusCn'
          },
          {
            label: '收费状态',
            prop: 'statusCn'
          },
        ],
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCode',
            show: true
          },
          {
            title: '财务确认',
            type: 'select',
            property: 'confirmStatus',
            show: true,
            options: [
              {
                label: '全部',
                value: ''
              },
              {
                label: '未确认',
                value: 1
              },
              {
                label: '已确认',
                value: 2
              },
              {
                label: '不确认',
                value: 3
              },
              {
                label: '已退回',
                value: 4
              },
            ]
          },
          {
            title: '录入开始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: []
          },
          {
            title: '收费状态',
            type: 'select',
            property: 'status',
            show: true,
            options: [
              {
                label: '全部',
                value: ''
              },
              {
                label: '未收费',
                value: 0
              },
              {
                label: '已收费',
                value: 1
              },
              {
                label: '已取消',
                value: 2
              },
            ]
          },
          {
            title: '录入结束时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
          {
            title: '录入人',
            type: 'text',
            property: 'createUser',
            show: true
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          createTimeStart: null,
          areaId: null,
          createTimeEnd: null,
          placeId: null,
          createUser: null,
          confirmStatus: null,
          status: null,
          feeType: 5,//3:电卡充值 4:其他收费项 5:抄电表费
          current: 1,
          size: 10,
        },
        count: null,
        total: {
          feeNum: 0,
          totalAmount: 0,
          electricServicePriceTotal: 0,
          electricPriceTotal: 0,
        },
        dialogVisible: false,//2021-2-5新增的费用录入的需求
        boothId: '',
        formRules: {
          // electricMeterPrice: vxRule(true, '', "blur", "查表电费不能为空"),
          // electricMeterServicePrice: vxRule(true, '', "blur", "查表服务管理费不能为空"),
          lastMonthDegrees: vxRule(true, '', "blur", "上月抄表底数不能为空"),
          lastDegrees: vxRule(true, '', "blur", "抄表底数不能为空"),
        },
        options1: [],//摊位号下拉
        title: '', //费用名称
        elePriceList: [], //电费信息
        form: { //费用录入传值
          areaName: '',
          placeName: '',
          boothCode: '',
          idCard: '',
          inDate: '',
          signPerson: '',
          phone: '',
          areas: '',
          contractTypeCn: '',
          expireDate: '',
          areaId: '',
          contractId: '',
          contractType: '',
          degrees: '',
          feeBeginDate: '',
          feeEndDate: '',
          feeType: 5,//3:电卡充值 4:其他收费项 5:抄电表费
          isConfirm: 2,
          orderId: '',
          placeId: '',
          remark: '',
          takeReturnProjectId: '',
          takeReturnProjectName: '',
          tenantId: '',
          tenantName: '',
          totalAmount: '',
          electricMeterServicePrice: 0.0,
          lastMonthDegrees: '0',
          lastDegrees:'0',
          electricMeterPrice: 0.0
        },
        dialogVisible2: false,
        multiForm: {
          file: '',
          isConfirm: 2
        },
        editDialogVisible: false
      }
    },
    computed: {
      baseUrl(){
        return process.env.VUE_APP_API_URL
      },
    },
    watch: {},
    created() {
      this.getData()
      this.getAreaLabel()
      this.cmboothinformationLabel()
    },
    components: {ETable, EDialog, EButton, EForm, EUpload},
    beforeMount() {

    },
    methods: {
      async mutiSave(){
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.expenseEntryUpdatee()
          } else {
            return false;
          }
        })
      },
      async expenseEntryUpdatee() {

        this.setDisabled(true)
        if (this.form.contractTypeCn == '长租') {
          this.form.contractType = 1
        } else if (this.form.contractTypeCn == '短租') {
          this.form.contractType = 2
        } else {
          this.form.contractType = 3
        }
        let res = await Http.expenseEntryUpdatee(this.form)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      editDialog(data) {
        this.editDialogVisible = true
        this.expenseEntryDetail(data.orderId)
      },
      //费用录入详情
      async expenseEntryDetail(orderId) {
        let res = await Http.expenseEntryDetail({id: orderId})
        if (res.code == 200) {
          this.form = res.data
          this.form.isConfirm = Number(res.data.isConfirm)
        }
      },
      async mutiSaveData() {
        let res = await Http.electricChargeExport(this.multiForm, [], true)
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.dialogVisible2 = false
          this.multiForm = {
            file: '',
            isConfirm: 2
          }
          this.getData()
        }
      },
      getList(data) {
        this.multiForm.file = data[0]
      },
      //摊位信息-摊位号下拉
      async cmboothinformationLabel() {
        //已租和未租的value值0和1
        let res = await Http.cmboothinformationLabel({isRent: 1, status: 1})
        if (res.code == 200) {
          this.options1 = res.data
        }
      },
      //费用录入详情
      async getContractByBoothId() {
        if (!this.boothId) {
          this.$message.error('请选择摊位号');
          return
        }
        let res = await Http.getContractByBoothId({id: this.boothId})
        let fields = ['areaId', 'areaName', 'placeName', 'boothCode', 'idCard', 'inDate', 'signPerson', 'phone', 'areas', 'contractTypeCn', 'expireDate', 'tenantId', 'tenantName', 'placeId', 'contractId']
        if (res.code == 200) {
          // 根据摊位号获得摊位合同详情
          let data = res.data
          fields.forEach(field => {
            this.form[field] = data[field]
          })
        } else {
          fields.forEach(field => {
            this.form[field] = ''
          })
        }
        let res1 = await Http.getLastMonthDgress({id: this.boothId})
        if(res1.code==200){
          this.form.lastMonthDegrees=res1.data
        }
      },
      saveData() {
        if (!this.boothId) {
          this.$message.error('请选择摊位号');
          return
        }
        console.log(this.$refs.form)
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.expenseEntry()
          } else {
            return false;
          }
        })

      },
      async expenseEntry() {
        this.setDisabled(true)
        if (this.form.contractTypeCn == '长租') {
          this.form.contractType = 1
        } else if (this.form.contractTypeCn == '短租') {
          this.form.contractType = 2
        } else {
          this.form.contractType = 3
        }
        let res = await Http.expenseEntry(this.form)
        if (res.code == 200) {
          this.setDisabled(false)
          this.$message.success(res.msg);
          this.cancelDialog()
          this.getData()
        }
      },
      dialogShow() {
        this.dialogVisible = true
      },
      changeSelect(prop) {
        if (prop == 'areaId') {
          this.getPlaceLabel()
        }
      },
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(current) {
        this.searchForm.current = current
        this.getData()
      },
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogVisible2 = false
        this.editDialogVisible=false
        this.boothId=''
        this.form = { //费用录入传值
          areaName: '',
          placeName: '',
          boothCode: '',
          idCard: '',
          inDate: '',
          signPerson: '',
          phone: '',
          areas: '',
          contractTypeCn: '',
          expireDate: '',
          areaId: '',
          contractId: '',
          contractType: '',
          degrees: '',
          feeBeginDate: '',
          feeEndDate: '',
          feeType: 5,//3:电卡充值 4:其他收费项 5:抄电表费
          isConfirm: 2,
          orderId: '',
          placeId: '',
          remark: '',
          takeReturnProjectId: '',
          takeReturnProjectName: '',
          tenantId: '',
          tenantName: '',
          totalAmount: '',
          electricMeterServicePrice: '0.0',
          lastMonthDegrees:'0',
          lastDegrees:'0',
          electricMeterPrice: '0.0'
        }
        this.multiForm.file=''
      },

      async getData() {
        let res = await Http.meterReadingList(this.searchForm)
        if (res.code == 200) {
          let data = res.data
          this.tableData = data.page.records
          this.count = data.page.total
          this.total = {
            feeNum: data.feeNum,
            totalAmount: data.totalAmount,
            electricPriceTotal: data.electricPriceTotal,
            electricServicePriceTotal: data.electricServicePriceTotal
          }
        }
      },
      remove(id) {
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.expenseEntryDelete(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      async expenseEntryDelete(id) {
        let res = await Http.expenseEntryDelete({id})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId: this.searchForm.areaId,
          status: null
        })
        if (res.code == 200) {
          this.formColumns[6].options = res.data
          this.locationOptions = res.data
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status: null
        })
        if (res.code == 200) {
          this.formColumns[3].options = res.data
          this.areaOptions = res.data
        }
      },
      reloadPrice(v){
        if(Number(this.form.lastMonthDegrees)>v){
          this.$message.error('本月抄表数应大于上月抄表数');
          return;
        }
        //查表服务费 （0.45）
        this.form.electricMeterServicePrice = (v-Number(this.form.lastMonthDegrees)) * 0.45

        //查表电费 (0.85)
        this.form.electricMeterPrice = (v-Number(this.form.lastMonthDegrees)) * 0.85
      },
      reloadPrice1(v){
        if(this.form.lastDegrees<v){
          this.$message.error('本月抄表数应大于上月抄表数');
          return;
        }
        //查表服务费 （0.45）
        this.form.electricMeterServicePrice = (this.form.lastDegrees - v) * 0.45

        //查表电费 (0.85)
        this.form.electricMeterPrice = (this.form.lastDegrees - v) * 0.85
      }
    }
  }
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }

    .title {
        font-weight: bold;
        padding-left: 10px;
        border-left: 2px solid #4d68ff;
        margin-bottom: 20px;
    }

    .download {
        color: #4D68FF;
        text-decoration: underline;
        padding: 0 10px;
    }
</style>
